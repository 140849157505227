@import "jsoneditor/dist/jsoneditor.min.css";
.dx-datagrid-rowsview.dx-empty {
  height: 40px !important;
}

auth-sign-in {
  display: flex;
  flex: 1;
}

.success-notification {
  @apply bg-gray-700 rounded;
}

ngx-mat-timepicker .mat-mdc-form-field-flex {
  padding: 0 !important;
}
ngx-mat-timepicker .mat-mdc-form-field-subscript-wrapper, ngx-mat-timepicker .mat-mdc-form-field-bottom-align {
  height: 0 !important;
}
ngx-mat-timepicker .mat-datepicker-content-container {
  align-items: end !important;
}
ngx-mat-timepicker .time-container {
  width: 100% !important;
}

::ng-deep .error-notification-snacbar {
  background-color: rgb(239, 69, 53) !important;
  color: rgb(239, 69, 53) !important;
}

::ng-deep snack-bar-container.error-notification-snacbar {
  padding: 20px;
  background-color: #dc2626 !important;
  color: white;
}

mat-snack-bar-container button .mdc-button__label {
  @apply text-white;
}
mat-snack-bar-container .mat-mdc-snack-bar-label {
  color: wheat;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 40px !important;
}

.main-list-table-line-1 > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 224px) !important;
}

.main-list-table-line-1.internal-table-class > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 323px) !important;
}

.main-list-table-line-1.user-internal-table-class > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 391px) !important;
}

.main-list-table-line-1-inline > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 300px) !important;
}

.dx-widget {
  max-width: 100% !important;
}

.dx-widget .dx-datagrid-pager .dx-pager {
  padding: 8px 16px !important;
}

.main-list-table-line-2 > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 230px) !important;
}

.main-list-table-line-3 > .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: calc(100vh - 380px) !important;
}

#availability-list-table .dx-gridbase-container > .dx-datagrid-rowsview {
  max-height: 100% !important;
}

.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dx-datagrid .dx-row > td {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  height: 40px !important;
  font-size: 14px;
  line-height: 20px;
}

.dx-datagrid .dx-header-row > td {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
}

.dx-datagrid tbody .dx-datagrid-filter-row > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.dx-datagrid tbody .dx-datagrid-filter-row > td .dx-texteditor-input {
  height: 40px !important;
}

.dx-datagrid .dx-editor-with-menu {
  height: 40px !important;
}

.dx-datagrid .dx-datagrid-content tbody tr > td {
  height: 40px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content, .dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 8px 4px;
}

.dx-datagrid .dx-datagrid-header-panel {
  background-color: whitesmoke !important;
}
.dx-datagrid .dx-datagrid-header-panel .dx-toolbar {
  background-color: whitesmoke !important;
}

.transaction-details-class .dx-datagrid-header-panel {
  background-color: white !important;
}
.transaction-details-class .dx-datagrid .dx-datagrid-header-panel .dx-toolbar {
  background-color: white !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  @apply justify-center text-2xl;
}

.fuse-confirmation-dialog-panel .mat-mdc-dialog-container .mdc-dialog__title {
  @apply justify-center text-2xl;
}
.fuse-confirmation-dialog-panel .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 0 !important;
}

.mdc-dialog__actions {
  padding: 8px 8px 20px 8px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0px !important;
}

.wallet-operation-master-class .dx-datagrid-nowrap {
  white-space: pre-line !important;
}

.fuse-splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #F9FAFB;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fuse-splash-screen .spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56px;
}

.fuse-splash-screen .spinner > div {
  width: 12px;
  height: 12px;
  @apply bg-orange-600;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
  animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

.fuse-splash-screen .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.fuse-splash-screen .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes fuse-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.example-handle {
  position: absolute;
  top: 17px;
  right: 5px;
  color: #959595;
  cursor: move;
  width: 15px;
  height: 15px;
}

ngx-mat-intl-tel-input .ngx-mat-tel-input-container {
  height: 40px !important;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container button {
  opacity: 1 !important;
  font-family: Lato-Regular !important;
  pointer-events: none !important;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container button span {
  display: flex;
  align-items: center;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container button span .country-selector-code {
  margin-top: 1px;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container button:hover {
  background-color: white !important;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container input:focus {
  --tw-ring-shadow: none;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container input {
  height: 39px !important;
  color: #1f2937 !important;
  font-size: 14px !important;
}
ngx-mat-intl-tel-input .ngx-mat-tel-input-container .country-selector-code {
  color: #1f2937 !important;
}

.max-h-88px {
  max-height: 88px !important;
}

app-promocode-edit-dialog .mat-horizontal-stepper-header {
  height: 50px !important;
}

app-promocode-edit-dialog .mat-step-header {
  background-color: transparent !important;
  color: transparent !important;
}

app-promocode-edit-dialog .mat-horizontal-content-container {
  overflow: hidden !important;
  padding: 0 0 0 0 !important;
}

.custom-mat-icon-class {
  width: 35px !important;
  height: 35px !important;
}

.saving-chip-class {
  background-color: #757575 !important;
  color: rgb(79, 79, 79) !important;
  background: #757575 !important;
}

app-promocode-edit-dialog .custom-stepper-promo-code-class .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}